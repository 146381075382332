import React from 'react'
import { Container, Link, Section, Seo } from '@components'
import { PageLayout } from '../components/site/layout/page-layout'

const NotFoundPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      <Section>
        <Container>
          <div className="flex flex-col items-center">
            <h1 className="my-16 text-[8rem] md:text-[12rem] text-red-700">
              404 :(
            </h1>
            <p className="mt-0 text-3xl text-red-700 ">Oops, Page Not Found</p>
            <p>
              Please try again or head to the{' '}
              <Link href="/">Approov home page</Link>.
            </p>
          </div>
        </Container>
      </Section>
    </PageLayout>
  )
}

export default NotFoundPage
